<template>
  <v-container grid-list-xs>
    <SectionCardsResumo />
    <!-- <SectionListPublicoAlvo /> -->
    <SectionListColaboradorCampanhaFuncao />
    <SectionRelatorios />
    <FilterApuracaoCards 
      v-if="getCampanha.id_tipo_dado === 'C'"
      :mesAnoCampanha="{mes_ref: getCampanha.mes_ref, ano_ref: getCampanha.ano_ref}"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FilterApuracaoCards from '@/components/campanha/apuracao/FilterApuracaoCards.vue';
export default {
  components: {
    // SectionListPublicoAlvo: () =>
      // import("@/components/campanha/apuracao/SectionListPublicoAlvo"),
    SectionListColaboradorCampanhaFuncao: () =>
      import(
        "@/components/campanha/apuracao/SectionListColaboradorCampanhaFuncao"
      ),
    SectionCardsResumo: () =>
      import("@/components/campanha/apuracao/SectionCardsResumo"),
    SectionRelatorios: () =>
      import("@/components/campanha/apuracao/SectionRelatorios"),
    FilterApuracaoCards
  },

  data() {
    return {
      
    };
  },

  computed: {
    ...mapGetters({
      getCampanha: "campanha/getCampanha"
    })
  },

  methods: {
    ...mapActions({
      actionCampanha: "campanha/actionCampanha",
    }),
  },

  mounted() {
    this.actionCampanha(this.$route.params.idCampanha);
  }
};
</script>

<style></style>
