<template>
  <div class="filter-box">
    <v-menu :close-on-content-click="false" max-width="320" offset-x>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" color="white" icon>
          <v-icon>mdi-tune-variant</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-container>
          <v-select
            label="Semanas"
            v-model="fechamento"
            :items="periodos"
            item-text="periodo"
            item-value="id_tipo_fechamento"
            clearable
          >

          </v-select>
          
        </v-container>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import comissaoService from "@/services/http/comissaoService";
export default {
  name: "FilterDashboard",

  props: {
    mesAnoCampanha: {
      type: Object
    },
  },

  data(){
    return {
      periodos: [],
      fechamento: ''
    }
  },

  watch:{
    mesAnoCampanha(value){
      this.fetchFechamentos(value);
    },

    fechamento(value){
      this.actionApuracaoColaboradorCampanha({
        id_tipo_fechamento: value,
        id_campanha: this.$route.params.idCampanha
      });
    }
  },

  methods: {
    ...mapActions({
      actionApuracaoColaboradorCampanha:
        "campanha/actionApuracaoColaboradorCampanha"
    }),

    async fetchFechamentos(filter) {
      try {
        let { data } = await comissaoService().tipoFechamento().show({
          ...filter,
          id_tipo_dado: 'C'
        });

        this.periodos = data.data.sort((a, b) => new Date(a.data_inicio) - new Date(b.data_inicio));

        this.periodos = this.periodos.map((item, index) => {
          return {
            periodo: `Semana ${index + 1}: ${this.formatDate(item.data_inicio)} - ${this.formatDate(item.data_final)}`,
            id_tipo_fechamento: item.id_tipo_fechamento
          }
        })

      } catch (error) {
        console.error(error);
      }
    },

    formatDate(date, monthFormat = "numeric") {
      if (!date) return "Não informado";
      const options = { year: "numeric", month: monthFormat, day: "numeric" };
      const newDate = new Date(date);

      return newDate.toLocaleDateString("pt-br", options);
    }
  }
};
</script>
<style scoped>
.filter-box {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 20%;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px 0 0 8px;
  text-align: center;
  cursor: pointer;
}
</style>
